import { Question, questions } from '../../model/schema'
import { Question1 } from './Question1/Question1'
import { Question2 } from './Question2/Question2'
import { Question3 } from './Question3/Question3'
import { Question4 } from './Question4/Question4'

import cls from '../QuestionsPage.module.scss'
import { Manager } from 'pages/QuestionsPage/ui/Questions/Manager/Manager'

interface Questions {
  page?: number
  questions: Question[]
}

export const Questions = (props: Questions) => {
  const { page, questions } = props

  const questionNumber = page ? page - 1 : 0

  switch (page) {
    case 1: {
      return <Question1 questionNumber={questionNumber} questions={questions} />
    }
    case 2: {
      return <Question2 questionNumber={questionNumber} questions={questions} />
    }
    case 3: {
      return <Question3 questionNumber={questionNumber} questions={questions} />
    }
    case 4: {
      return <Question4 questionNumber={questionNumber} questions={questions} />
    }
    default: {
      return <Question1 questionNumber={questionNumber} questions={questions} />
    }
  }
}

interface QuestionsBlock {
  paramsId: number
  isMobile?: boolean
}

export const QuestionsBlock = (props: QuestionsBlock) => {
  const { paramsId, isMobile } = props

  return (
    <div className={cls.questions}>
      <h2>{questions?.[paramsId - 1]?.text}</h2>

      {isMobile && <Manager paramsId={paramsId} isMobile={isMobile} />}

      <div className={cls.content}>
        <Questions page={paramsId} questions={questions} />
      </div>
    </div>
  )
}
