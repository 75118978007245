import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTES } from 'shared/config/routes'
import LogoFullIIcon from 'shared/assets/icons/LogoFullIIcon.webp'

import styles from './Logo.module.scss'

interface Logo {
  className?: string
}

export function Logo(props: Logo) {
  const { className } = props

  return (
    <NavLink to={ROUTES.MAIN} className={classNames(styles.logo, className)}>
      <img src={LogoFullIIcon} alt="LogoFullIIcon" />
    </NavLink>
  )
}
