import HelperImg from 'shared/assets/images/chat/HelperImg.webp'
import DotIcon from 'shared/assets/icons/DotIcon.webp'

import cls from 'pages/QuestionsPage/ui/QuestionsPage.module.scss'

interface Manager {
  paramsId: number
  isMobile?: boolean
}

const managerComments = [
  'Мы подберем Вам подходящие условия для инвестирования!',
  'Инвестировать в Freedom можно всего от 1 200 000 сум',
  'Ваш личный менеджер поможет разобраться с настройками биржи для первой сделки',
  'Исходя из Вашей цели инвестиций мы найдем оптимальный пакет акций',
]

export const Manager = (props: Manager) => {
  const { paramsId, isMobile } = props

  return (
    <div className={cls.card}>
      <img src={HelperImg} alt="HelperImg" />
      <div className={cls.card__container}>
        <div className={cls.card__title_container}>
          <p className={cls.card__title}>Анвар</p>
          <picture className={cls.card__dot}>
            <img src={DotIcon} alt="DotIcon" />
          </picture>
          <p className={cls.card__desc}>
            {isMobile ? 'Менеджер Freedom' : 'Менеджер Freedom Finance'}
          </p>
        </div>
        <p className={cls.card__message}>{managerComments[paramsId - 1]}</p>
      </div>
    </div>
  )
}
