import useAppContext from 'app/store/useAppContext'
import { setIsAffirmationModal, setIsSuccessAffirmationModal } from 'app/store/actions'
import useReactPixelMetric from 'entities/user/use-cases/useReactPixelMetric'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'
import CheckModalIcon from 'shared/assets/icons/CheckModalIcon.webp'

import styles from './AcceptModal.module.scss'

export default function AcceptModal() {
  const {
    store: {
      user: {
        searchParams: { metric },
      },
      modals: { isSuccessAffirmationModal },
    },
    dispatch,
  } = useAppContext()

  const closeModal = () => {
    dispatch(setIsSuccessAffirmationModal(false))
    dispatch(setIsAffirmationModal(false))
  }
  const onAccept = () => {
    closeModal()
  }

  useReactPixelMetric(isSuccessAffirmationModal, metric)

  return (
    <>
      <Modal
        isOpen={isSuccessAffirmationModal}
        onRequestClose={closeModal}
        content={
          <div className={styles.modal__content}>
            <img src={CheckModalIcon} alt="accept" />
            <h2 className={styles.modal__title}>
              Ваша заявка принята в работу
            </h2>
            <p className={styles.modal__desc}>
              Ожидайте звонка менеджера Freedom Finance в течение рабочего дня
            </p>
            <Button onClick={onAccept}>Продолжить</Button>
          </div>
        }
      />
    </>
  )
}
