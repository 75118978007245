import { Button } from 'shared/ui/button'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'

import CongratulateIcon from 'shared/assets/icons/CongratulateIcon.png'

import cls from './GiftPage.module.scss'
import { getCurrentDate } from 'shared/utils/functions'

export const GiftPage = () => {
  const currenDate = getCurrentDate()

  return (
    <section className={cls.page}>
      <div className={cls.gift_data}>
        <img src={CongratulateIcon} alt="CongratulateIcon" />
        <h3>Спасибо за ответы!</h3>
        <h3>Мы дарим Вам</h3>
        <h2>600 000 сум</h2>
        <p>Заберите Ваш подарок до {currenDate}</p>
        <p>- он будет начислен к сумме первого депозита</p>
      </div>
      <div className={cls.cards}>
        <div className={cls.cards__top_block}>
          <div className={cls.cards__card}>
            <p>Ежемесячный доход</p>
            <p>
              <span>6 000 000 сум</span>
            </p>
          </div>
        </div>
        <div className={cls.cards__bottom_block}>
          <div className={cls.cards__card}>
            <p>Размер комиссии</p>
            <p>
              <span className={cls.card_desc}>0%</span>
            </p>
          </div>
          <div className={cls.cards__card}>
            <p>Начальная сумма</p>
            <p>
              <span className={cls.card_desc}>1 200 000 сум</span>
            </p>
          </div>
        </div>
      </div>
      <NavLink to={ROUTES.CONTACTS} className={cls.button}>
        <Button>Забрать подарок</Button>
      </NavLink>
    </section>
  )
}
