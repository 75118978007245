import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Header from 'widgets/header'
import Footer from 'widgets/footer'
import useSetUserParams from 'entities/user/use-cases/useSetUserParams'
import useSetUserApiData from 'entities/user/use-cases/useSetUserApiData'
import useSetIsMobileModal from 'features/modal/use-cases/useSetIsMobileModal'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import { ROUTES } from 'shared/config/routes'
import classNames from 'shared/lib/class-names'

export const MainLayout = () => {
  const { isMobile, isMobileModal, isMobileSmall, isSomeModalsOpened } =
    useIsMobileModal()
  const { pathname } = useLocation()
  const isContactsPage = pathname === ROUTES.CONTACTS
  const isQuestionsPage = pathname?.includes(ROUTES.QUESTIONS)

  useSetIsMobileModal()
  useSetUserParams()
  useSetUserApiData()

  return (
    <div className="app">
      {!isContactsPage && !isQuestionsPage && <Header />}
      {isContactsPage && isMobileSmall && <Header />}
      {isQuestionsPage && isMobile && <Header />}
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>
      {isQuestionsPage && <Footer />}
    </div>
  )
}
