export interface Question {
  id: number
  text: string
  options: string[]
}

export const questions: Question[] = [
  {
    id: 1,
    text: 'Был ли у Вас опыт в инвестировании?',
    options: ['Да, вкладывал/a в акции', 'Нет, не имел/а опыта'],
  },
  {
    id: 2,
    text: 'Какую сумму Вы бы хотели инвестировать?',
    options: ['1 200 000 сум'],
  },
  {
    id: 3,
    text: 'Нужна ли Вам помощь финансового эксперта?',
    options: ['Да, я хочу консультацию', 'Нет, я беру риски на себя'],
  },
  {
    id: 4,
    text: 'Почему Вы решили инвестировать в Freedom?',
    options: [
      'Хочу приумножить деньги',
      'Ищу дополнительный доход',
      'Планирую крупную покупку',
      'Собираюсь долго копить',
    ],
  },
]
